// import React, { useState, useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
// import ShoeSpinner from "./Components/ShoeSpinner";
import GifMaker from "./Components/GifMaker";
import Background from "./Components/Resources/Background2.png";
import BackgroundImage from "./Components/Resources/Background.jpeg";
import gifshot from "gifshot";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import React, { Suspense, useState, useRef, useEffect } from "react";
const ShoeSpinner = React.lazy(() => import("./Components/ShoeSpinner"));

const shoes = {
  1: "af1.glb",
  2: "superstar.glb",
  3: "forum.glb",
  4: "puma.glb",
};

// Define a mapping from URL param names to material names
const paramToMaterialMap = {
  AC1: "ACCENT.001",
  AC2: "ACCENT.002",
  AC3: "ACCENT.003",
  AC4: "ACCENT.004",
  HE1: "HEEL.001",
  HE2: "HEEL.002",
  LA1: "LACES.001",
  SO1: "SOCK.001",
  SO2: "SOCK.002",
  SL1: "SOLE.001",
  SL2: "SOLE.002",
  SL3: "SOLE.003",
  ST1: "STRAP.001",
  ST2: "STRAP.002",
  TO1: "TOE.001",
  TO2: "TOE.002",
  TG1: "TONGUE.001",
  TG2: "TONGUE.002",
};

const App = () => {
  const [isRecording, setIsRecording] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);

  // Determine the model based on a query parameter
  const shoeKey = params.get("model"); // 'model' is the query param for the shoe model
  const modelPath = shoes[shoeKey] ? `${shoes[shoeKey]}` : "af1.glb"; // Default to af1.glb if not specified or invalid

  const colors = Object.keys(paramToMaterialMap).reduce((acc, key) => {
    const materialName = paramToMaterialMap[key];
    acc[materialName] = "#ffffff"; // Set default color to white
    return acc;
  }, {});
  // Iterate over all entries in the URLSearchParams instance
  params.forEach((value, key) => {
    // Map the custom param name to the actual material name, if it exists
    const materialName = paramToMaterialMap[key];
    if (materialName) {
      colors[materialName] = `#${value}`;
    }
  });

  const canvasRef = useRef(); // Create a ref

  const ffmpeg = createFFmpeg({ log: true });

  // Load FFmpeg modules
  useEffect(() => {
    const loadFFmpeg = async () => {
      if (!ffmpeg.isLoaded()) {
        try {
          await ffmpeg.load();
        } catch (error) {
          console.error("Failed to load FFmpeg", error);
        }
      }
    };

    loadFFmpeg();
  }, []);

  const captureFrames = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    setIsRecording(true);
    const stream = canvas.captureStream(30);
    let options = { mimeType: "video/webm" };
    const recorder = new MediaRecorder(stream, options);
    let chunks = [];

    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = async () => {
      const blob = new Blob(chunks, { type: "video/webm" });
      const mp4Blob = await convertWebmToMp4(blob); // Use the conversion function here
      // handleShare(mp4Blob);
    };

    recorder.start();
    setTimeout(() => recorder.stop(), 7050); // Adjust as needed
  };

  const convertWebmToMp4 = async (webmBlob) => {
    const formData = new FormData();
    formData.append("video", webmBlob);
    fetch("https://api.imgur.com/3/image", {
      method: "POST",
      headers: {
        Authorization: `Client-ID b87cf93e088037a`,
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })

      .then((data) => {
        if (data.success) {
          // const url = data.data.link.replace("http://", "https://");
          console.log("Image uploaded:", data);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const handleShare = async (mp4Blob) => {
    if (navigator.canShare && navigator.canShare({ files: [mp4Blob] })) {
      try {
        await navigator.share({
          files: [mp4Blob],
          title: "Check out my creation!",
          text: "Just made this awesome shoe design. Take a look!",
        });
        console.log("Sharing was successful.");
      } catch (error) {
        console.error("Sharing failed:", error);
      }
    } else {
      downloadFile(mp4Blob, "shoe-design.mp4");
    }
  };

  // The rest of your App component...

  return (
    <>
      <div className="App">
        <img src={Background} className="background" />
        <div className={`overlay ${isRecording ? "show" : ""}`}>Loading...</div>
        <Suspense fallback={<div className="loading">ANALYZING KICKS</div>}>
          <button onClick={captureFrames} className="button">
            SHARE VIDEO
          </button>
          <div className={isRecording ? "recording" : "viewing"}>
            <ShoeSpinner
              ref={canvasRef}
              model={modelPath}
              colors={colors}
              isRecording={isRecording}
            />
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default App;
